import React from 'react';

import Badge from 'react-bootstrap/esm/Badge';
import Popover from 'react-bootstrap/esm/Popover';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

import { IFileResponse, REALTIME_CURRENT_WEATHER, REALTIME_CURRENT_TIME, REALTIME_COUNTDOWN, REALTIME_COUNTUP, REALTIME_FORECAST } from '@/types/file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FileDetailsBadgesProps {
  file: IFileResponse,
  hideFileType?: boolean,
}

interface BadgeData {
  label: string,
  tooltip: string,
  icon: IconProp,
}

const REALTIME_TYPE_DATA_MAP: Record<string, BadgeData> = {
  [REALTIME_CURRENT_WEATHER]: {
    label: 'Temp',
    tooltip: 'This content uses realtime weather data that will be displayed over this file on your sign.',
    icon: 'temperature-three-quarters',
  },
  [REALTIME_CURRENT_TIME]: {
    label: 'Time',
    tooltip: 'This content uses realtime time data that will be displayed over this file on your sign.',
    icon: 'clock',
  },
  [REALTIME_COUNTDOWN]: {
    label: 'Countdown',
    tooltip: 'This content uses realtime date data that will display a countdown on your sign.',
    icon: 'hourglass',
  },
  [REALTIME_COUNTUP]: {
    label: 'Countup',
    tooltip: 'This content uses realtime date data that will display a countup on your sign.',
    icon: 'hourglass',
  },
  [REALTIME_FORECAST]: {
    label: 'Forecast',
    tooltip: 'This content uses realtime forecast data that will be displayed over this file on your sign.',
    icon: 'sun',
  },
};

const FileDetailsBadges = (props: FileDetailsBadgesProps) => {
  const { file, hideFileType } = props;

  const uniqueFileBadgeMap = file.realtimeTypes
    .reduce((acc, realtimeType) => {
      const validRealtimeType = Object.keys(REALTIME_TYPE_DATA_MAP).find((realtimeTypeKey) => {
        const containsRealtimeKeyPattern = new RegExp(realtimeTypeKey);
        return containsRealtimeKeyPattern.test(realtimeType);
      });
      if (validRealtimeType) {
        acc[validRealtimeType] = REALTIME_TYPE_DATA_MAP[validRealtimeType];
      }

      return acc;
    }, {} as Record<string, BadgeData>);

  return (
    <div>
      {!hideFileType && (<Badge bg="secondary">
        {file.fileType}
      </Badge>)}
      {Object.values(uniqueFileBadgeMap)
        .map((badgeData) => (
          <OverlayTrigger
            key={badgeData.label}
            trigger={['focus', 'hover']}
            rootClose
            overlay={
              (<Popover>
                <Popover.Body>
                  {badgeData.tooltip}
                </Popover.Body>
              </Popover>)
            }
          >
            <Badge
              className="ms-2"
              style={{
                backgroundColor: '#0093C3',
              }}
              bg="none"
            >
              <FontAwesomeIcon
                icon={badgeData.icon}
                className="me-2"
              />
              {badgeData.label}
            </Badge>
          </OverlayTrigger>
        ),
        )}
    </div>
  );
};

export default FileDetailsBadges;
